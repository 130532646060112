import { Card, Col, Image, Row } from 'antd'
import React from 'react'

export default function StoreGallery(props) {
    return (
        <>
            <Card title="ภาพสินค้าในร้าน" bordered={false}>
                <Row>
                    {props.data?.map((val, index) => {
                        return (
                            <Col
                                key={index} xs={24} sm={12} md={12} lg={6} xl={6} xxl={4}
                                style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}
                            >
                                <Image
                                    src={val.imageUrl}
                                    alt="Snow"
                                    style={{ objectFit: "cover", width: '100%', height: 180 }}
                                ></Image>
                            </Col>
                        )
                    })}
                </Row>
            </Card>
        </>
    )
}
